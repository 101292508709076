import {backendApi} from "../../backend-api-sr"

export const listOfPPP = (() =>{
    const fetchCustName = ( async (subPath) => {
        var url = '/api/v3/sr/shipment-analysis/list-of-ppp/filter-cust-name'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchProdCode = ( async (subPath) => {
        var url = '/api/v3/sr/shipment-analysis/list-of-ppp/filter-prod-code'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchIndex = ( async (subPath) => {
        var url = '/api/v3/sr/shipment-analysis/list-of-ppp'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchDetail = ( async (subPath) => {
        var url = '/api/v3/sr/shipment-analysis/list-of-ppp/get-detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {fetchCustName, fetchProdCode, fetchIndex, fetchDetail};
})()