<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">            
                <v-container fluid>
                    <v-row>
                        <v-col class="col-12" md="8">
                            <v-card outlined>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <span class="blue-lcd mb-2 font-12">Customer </span>
                                            <v-autocomplete dense outlined class="ma-0 pa-1 mt-2" hide-details=true v-model="customer"
                                                :items="customers" default="" item-value="cust_id" item-text="cust_name" 
                                                clearable prepend-inner-icon="mdi-account-box">
                                                <!-- @change="(event) => getMachId(event)" -->
                                                <!-- :disabled="disabledRegion" -->
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <span class="blue-lcd mb-2 font-12">PPP/LPM  </span>
                                            <v-autocomplete dense outlined class="ma-0 pa-1 mt-2" hide-details=true v-model="ppp"
                                                :items="ppps" default=""
                                                clearable  prepend-inner-icon="mdi-truck">
                                            </v-autocomplete>
                                        </v-col>                            
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <span class="blue-lcd mb-2 font-12">Start Periode <strong style="color:red;">*</strong><span></span></span>
                                            <v-menu ref="modal" 
                                            v-model="modal" 
                                            :close-on-content-click="false" 
                                            transition="scale-transition" 
                                            offset-y 
                                            max-width="290px" 
                                            min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    dense
                                                    clearable
                                                    outlined 
                                                    v-model="period" 
                                                    persistent-hint 
                                                    append-icon="mdi-calendar" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    class="ma-0 pa-1 mt-2" 
                                                    hide-details=true>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker 
                                                    v-model="period" 
                                                    type="month" 
                                                    no-title @input="modal = false">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3">
                                            <span class="blue-lcd mb-2 font-12">End Periode </span>
                                            <v-menu ref="modal_to_period" 
                                            v-model="modal_to_period" 
                                            :close-on-content-click="false" 
                                            transition="scale-transition" 
                                            offset-y 
                                            max-width="290px" 
                                            min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    dense
                                                    clearable
                                                    outlined 
                                                    v-model="period_to" 
                                                    persistent-hint 
                                                    append-icon="mdi-calendar" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    class="ma-0 pa-1 mt-2" 
                                                    hide-details=true>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker 
                                                    v-model="period_to" 
                                                    type="month" 
                                                    no-title @input="modal_to_period = false">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="2" md="2">
                                            <v-btn color="info" elevation="2" @click="getPullData()"><v-icon small class="mr-3">mdi-magnify</v-icon> Search</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                            
                        </v-col>
                        <v-col class="col-12" md="4">
                            <v-container fluid class="pa-2">
                                <v-row>
                                    <v-col class="col-12" sm="6" md="6">
                                        <v-card
                                            class="ma-0 pt-0"
                                            min-height="120"
                                            outlined
                                        >
                                            <v-card-text>
                                                <div class="p-2">
                                                    <div class="font-12"> Total Weight </div>
                                                    <div class="text-h5 font-weight-bold mt-1 mb-0 primary--text">
                                                    {{ $store.getters.convertToCurrencyUs(total_wgt) }}
                                                    </div>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col> 
                                    <v-col class="col-12" sm="6" md="6">
                                        <v-card
                                            class="ma-0 pt-0"
                                            min-height="120"
                                            outlined
                                        >
                                            <v-card-text class="">
                                                <div class="p-2">
                                                    <div class="font-12"> Total Price </div>
                                                    <div class="text-h5 font-weight-bold mt-1 mb-0 success--text">
                                                        {{ $store.getters.convertToCurrencyNoFrac(total_price_tot) }}
                                                    </div>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="20"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    fixed-header
                                    height="460"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >        
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-100">
                                            List
                                            <v-spacer></v-spacer>
                                            <v-text-field solo style="max-width: 300px;" class="mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            <v-btn style="padding: 19px;" small color="#005c37" elevation="2" class="text-white">
                                                <v-icon>mdi-file-excel</v-icon>
                                                <download-excel
                                                    class="text"
                                                    :fetch           = "exportExcel"
                                                    :fields="headersColumn"
                                                    :before-generate = "startDownload"
                                                    :before-finish   = "finishDownload">
                                                    Export to Excel
                                                </download-excel> 
                                            </v-btn>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.wgt`]="{ item }">
                                    {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                </template>
                                <template v-slot:[`item.total_price`]="{ item }">
                                    {{ $store.getters.convertToCurrencyNoFrac(item.total_price) }}
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="showDialogDetail(item)" v-on="on">
                                                        mdi-eye
                                                    </v-icon>
                                                </template>
                                                <span>View Detail</span>
                                            </v-tooltip>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-dialog v-model="dialog" max-width="1000px">                
                <v-card>
                    <!-- <v-card-title><span class="blue--text">PPP/LPM no: {{ pppLpmNo }}</span></v-card-title> -->
                    <v-card-title>
                            <v-col cols="6">
                               Trans Date : <span class="cyan-text text-darken-3 m-0"> {{ dtTrx }}</span>
                            </v-col>
                            <v-col cols="6">
                                Customer : <span class="cyan-text text-darken-3 m-0"> {{ customerName }}</span>
                            </v-col>    
                            <v-col cols="6">
                                Lpm/PPP No : <span class="cyan-text text-darken-3 m-0"> {{ pppLpmNo }}</span>
                            </v-col>
                            <v-col cols="6">
                                Order No : <span class="cyan-text text-darken-3 m-0"> {{ orderNo }}</span>
                            </v-col>    
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headerDetails"
                                    :items="formattedDetailData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="10"
                                    fixed-header
                                    height="500"
                                    :divider="true"
                                    :light="true"
                                    
                                >        
                                <!-- <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-100">
                                            <h6 class="cyan-text text-darken-3 m-0">List</h6>
                                            <v-spacer></v-spacer>
                                            <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template> -->
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialogDetail">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import {listOfPPP} from "../../../backend-api/sr/shipment_analysis/list_of_ppp"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data(){
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'SR',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Shipment Analysis',
                    disabled: true,
                    href: '/admin/sr',
                },
                {
                    text: 'List of PPP',
                    disabled: true
                }
            ],
            // filter
            customer:'',
            customers:[],
            prodCode:'',
            prodCodes:[],
            ppp:'',
            ppps:['All','PPP', 'LPM'],
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            tableVisible:false,
            header:[
                {
                    text: 'Trans Date',
                    align: 'start',
                    value: 'dt_trx', 
                    align:'left'},
                { text: 'PPP/LPM', value: 'aprv_flag' , align:'left'},
                { text: 'LPM No', value: 'lpm_id' , align:'left'},
                { text: 'Order No', value: 'order_id' , align:'left'},
                { text: 'Cust Name', value: 'cust_name' , align:'left'},
                { text: 'Weight', value: 'wgt' , align:'right'},
                { text: 'Total Price', value: 'total_price' , align:'right'},
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            headerDetails:[
                {
                    text: 'Trans Date',
                    align: 'start',
                    value: 'dt_trx', 
                    align:'left'},
                { text: 'PPP/LPM', value: 'aprv_flag' , align:'left'},
                
                { text: 'Order No', value: 'order_id' , align:'left'},
                { text: 'Cust Name', value: 'cust_name' , align:'left'},
                { text: 'Item No', value: 'item_num' , align:'left'},
                { text: 'Prod Code', value: 'prod_code' , align:'left'},
                { text: 'Description', value: 'descr' , align:'left'},
                { text: 'Weight', value: 'wgt' , align:'right'},
                { text: 'Unit Price', value: 'unit_price' , align:'right'},
                { text: 'Total Price', value: 'total_price' , align:'right'},
            ],
            headersColumn:{
                'Trans Date': 'dt_trx', 
                'PPP/LPM': 'aprv_flag',
                'LPM No' : 'lpm_id',
                'Order No' : 'order_id',
                'Customer Name' : 'cust_name',
                'Description': 'descr',
                'Weight': 'wgt',
                'Unit Price': 'unit_price',
                'Total Price': 'total_price'
            },
            searchItem: '',
            data:[],
            detailData:[],
            dialog:false,
            dtTrx:'',
            customerName:'',
            pppLpmNo:'',
            orderNo:'',
            total_wgt: 0,
            total_price_tot: 0
        }
    },
    beforeRouteLeave(to, from, next) {
        // Reset component variables
        console.log('test route leave')
        console.log(to, from, next)
        this.a = '';
        this.b = '';
        this.c = '';

        // Continue with the route navigation
        next();
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getCustName()
        this.$store.dispatch('setOverlay', false)
        // await this.getProdCode()
    },
    computed:{
        formattedDetailData() {
            return this.detailData.map(item => ({
                ...item,
                wgt: this.formatNumber(item.wgt, 0, 0),
                unit_price: this.formatNumber(item.unit_price, 2, 2),
                total_price : this.formatNumber(item.total_price, 2, 2)
            }));
        },
        // total_wgt(){
        //     var total_wgt = 0

        //     for (let index = 0; index < this.data.length; index++) {
        //         total_wgt = total_wgt + Number(this.data[index].wgt);
        //     }
            
        //     return (total_wgt | 0)
        // },
        // total_price_tot(){
        //     var total_price_tot = 0

        //     for (let index = 0; index < this.data.length; index++) {
        //         total_price_tot = total_price_tot + parseFloat(Number(this.data[index].total_price));
        //     }
            
        //     return (total_price_tot | 0)
        // }
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        formatNumber(number, minFrac, maxFrac) {
            var a = parseFloat(number)
            return a.toLocaleString(undefined, {
                minimumFractionDigits: minFrac,
                maximumFractionDigits: maxFrac
            });
        },
        async getCustName(event){
            const respData = await listOfPPP.fetchCustName('', null, false, false, false)
            if (respData.status === 200) {
                this.customers = respData.data.data
            }
        },
        async getProdCode(event){
            const respData = await listOfPPP.fetchProdCode('', null, false, false, false)
            if (respData.status === 200) {
                this.prodCodes = respData.data.data
            }
        },
        async getPullData(event){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period start",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''

                var reqBody = {
                    'cust_id': this.customer ? this.customer : '',
                    'prod_code': this.prodCode ? this.prodCode : '',
                    'aprv': this.ppp,
                    'periodStart':periodStart,
                    'periodEnd': periodEnd
                }
                const respDataSummary = await backendApi.fetchCore('/api/v3/sr/shipment-analysis/list-of-ppp/summary', reqBody, false, false, false)
                const respData = await listOfPPP.fetchIndex(`?cust_id=${this.customer}&prod_code=${this.prodCode}&aprv=${this.ppp}&periodStart=${periodStart}&periodEnd=${periodEnd}`, null, false, false, false)
                if (respData.status === 200 && respDataSummary.status === 200) {
                    this.total_wgt = respDataSummary.data.data.wgt ? respDataSummary.data.data.wgt : 0
                    this.total_price_tot = respDataSummary.data.data.total_price ? respDataSummary.data.data.total_price : 0
                    this.data = respData.data.data
                    this.tableVisible = true
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                    return false
                }
            }
        },
        async showDialogDetail(event){
            console.log(event)
            await this.getDetailData(event)
            this.dialog = true
            this.dtTrx = event.dt_trx
            this.customerName = event.cust_name.trim()
            this.pppLpmNo = event.lpm_id.trim()
            this.orderNo = event.order_id.trim()
        },
        closeDialogDetail(event){
            this.dtTrx = ''
            this.customerName = ''
            this.pppLpmNo = ''
            this.orderNo = ''
            this.dialog = false
            this.$store.dispatch('setOverlay', false)
        },
        async getDetailData(event){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period start",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
                const respData = await listOfPPP.fetchDetail(`?cust_id=${event.cust_id}&lpm_id=${event.lpm_id}`, null, false, false, false)
                if (respData.status === 200) {
                    this.detailData = respData.data.data
                    this.tableVisible = true
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                    return false
                }
            }
        },
        async exportExcel(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
                const respData = await listOfPPP.fetchDetail(`?cust_id=${this.customer}&prod_code=${this.prodCode}&aprv=${this.ppp}&periodStart=${periodStart}&periodEnd=${periodEnd}`, null, false, false, false)
                return respData.data.data
            }
        },

        startDownload(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
            }
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch:{},
}
</script>

<style>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>
